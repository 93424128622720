import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper wrapper-content animated fadeIn" }
const _hoisted_2 = {
  class: "nav nav-tabs",
  role: "tablist"
}
const _hoisted_3 = ["innerHTML", "onClick"]
const _hoisted_4 = { class: "tab-content w-100 bg-white" }
const _hoisted_5 = {
  role: "tabpanel",
  class: "tab-pane active"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (tab) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass({active: $setup.isActive(tab)}),
            key: tab.name
          }, [
            _createElementVNode("a", {
              href: "#",
              role: "tab",
              "data-toggle": "tab",
              innerHTML: tab.title,
              onClick: ($event: any) => ($setup.setTab(tab))
            }, null, 8, _hoisted_3)
          ], 2))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.currentTab), {
            tablename: $setup.currentTab,
            params: [{field: 'transfers', value: $props.id}]
          }, null, 8, ["tablename", "params"]))
        ])
      ])
    ])
  ]))
}