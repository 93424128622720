import {baseURL} from "@/api/erpApi";

export default {
    mtype: "GET",
    datatype: "json",
    height: "auto",
    autowidth: true,
    rowList: [10, 20, 50, 100],
    rowNum: 10,
    page: 1,
    loadtext: '<p>Cargando...</p>',
    hoverrows: false,
    refresh: true,
    multiselect: true,
    url: baseURL + '/payments',
    colNames: [
        "Nro. de pago",
        "Proveedor",
        "Fecha de creación",
        "Fecha de pago aplicado",
        "Total",
        "Nro. de documento",
        "Método de pago",
        "Estado",
        '',
        '',
    ],
    colModel: [
        {name: "code", width: 55},
        {name: "vendorName", width: 55},
        {name: "paymentDate", width: 55},
        {name: "appliedDate", width: 55},
        {name: "totalLabel", width: 55},
        {name: "documentCodes", width: 55},
        {name: "paymentMethods", width: 55},
        {name: "statusLabel", width: 40, align: 'center'},
        {name: "options", width: 55, align: 'center'},
        {name: "hiddenOptions", hidden: true}
    ],
    sortname: 'code',
    sortorder: "desc",
    viewrecords: true,
    gridview: true
};
