
import GeneralData from "@/modules/pettyCash/components/TransferGeneralData.vue";
import useTransfer from "@/modules/pettyCash/composables/useTransfer";
import toastr from "toastr";
import {onMounted, ref} from "vue";
import router from "@/router";
import Tabs from "@/modules/pettyCash/components/TransferTabs.vue";

export default {
    props: ['id', 'transferId'],
    components: {GeneralData, Tabs},
    setup(props: { id: string, transferId: string }) {
        const {clearForm, transferForm, updateTransfer, catalogs, getTransfer} = useTransfer()
        const sending = ref(false)
        const loading = ref(true)

        clearForm();

        onMounted(async () => {
            transferForm.value = await getTransfer(props.transferId)
            loading.value = false
        })

        return {
            mode: 'EDIT',
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'petty_cash.edit', params: {id: props.id}}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updateTransfer(transferForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'petty_cash.edit', params: {id: props.id}})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
