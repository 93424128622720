
import {Ref, ref} from "vue";
import {Tab} from "@/interfaces";
import PaymentMainTable from "@/modules/payments/components/MainTable.vue"

export default {
    props: ['id'],
    components: {
        payments: PaymentMainTable
    },
    setup() {
        const currentTab = ref('payments')
        const tabs: Ref<Tab[]> = ref([
            {name: 'payments', title: 'Pagos de compras'}
        ])

        return {
            currentTab,
            tabs,
            isActive: (tab: Tab) => tab.name === currentTab.value,
            setTab: (tab: Tab) => currentTab.value = tab.name
        }
    }
}
