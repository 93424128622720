
import {onMounted, ref, watch} from "vue";
import useTransfer from "@/modules/pettyCash/composables/useTransfer";
import usePanel from "@/composables/usePanel";
import useSelect2 from "@/composables/useSelect2";

const {transferForm} = useTransfer()

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()
        const {select2account} = useSelect2(`?isLeaf=1`)

        const bankAccountId = ref('')
        const amount = ref(0)
        const date = ref('')

        onMounted(() => {
            bankAccountId.value = transferForm.value.bankAccountId
            amount.value = transferForm.value.amount
            date.value = transferForm.value.date
        })


        watch(bankAccountId, val => transferForm.value.bankAccountId = val)
        watch(amount, val => transferForm.value.amount = val)
        watch(date, val => transferForm.value.date = val)

        return {
            select2account,
            showPanel,
            togglePanel,
            bankAccountId,
            amount,
            date,
            transferForm
        }
    }
}
