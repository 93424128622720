import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {TransferForm} from "@/modules/pettyCash/interfaces"
import store from "@/store";
import moment from "moment";

const {companyId} = useAuth()
const defaultValues = (): TransferForm => {
    return {
        id: uuid(),
        pettyCashId: "",
        bankAccountId: "",
        amount: 0,
        date: moment().format('DD/MM/YYYY'),
        paymentId: uuid(),
        paymentCode: null,
        paymentStatus: null,
        companyId: companyId.value
    }
}

const transferForm: Ref<TransferForm> = ref(defaultValues())

const useTransfer = () => {
    const catalogs = {};
    const clearForm = () => transferForm.value = defaultValues()

    const createTransfer = async (transferForm: TransferForm) => {
        return await store.dispatch('pettyCash/createTransfer', transferForm)
    }

    const updateTransfer = async (transferForm: TransferForm) => {
        return await store.dispatch('pettyCash/updateTransfer', transferForm)
    }

    const getTransfer = async (id: string): Promise<TransferForm> => {
        const {ok, data} = await store.dispatch('pettyCash/getTransfer', id)

        return data;
    }


    return {
        transferForm,
        catalogs,
        clearForm,
        createTransfer,
        updateTransfer,
        getTransfer
    }
}

export default useTransfer;
